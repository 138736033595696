<template>
  <div>
    <a-card :tabList="tabList" :activeTabKey="currentTab" @tabChange="tabChange">
      <MaintenanceTask v-if="currentTab == 'maintenance_task'" />
      <MaintenanceRecord v-if="currentTab == 'maintenance_record'" />
    </a-card>
  </div>
</template>

<script>
export default {
  components: {
    MaintenanceTask: () => import("./MaintenanceTask"),
    MaintenanceRecord: () => import("./MaintenanceRecord"),
  },
  data() {
    return {
      tabList: [
        {
          key: "maintenance_task",
          tab: "点检保养任务",
        },
        {
          key: "maintenance_record",
          tab: "点检保养记录",
        },
      ],
      currentTab: "maintenance_task",
    };
  },
  methods: {
    tabChange(key) {
      this.currentTab = key;
      this.$router.push({ query: { currentTab: this.currentTab } });
    },
  },
};
</script>

<style scoped></style>
